.shutter {
  opacity: 0;
  transition: all 30ms ease-in; /* Shutter speed */
  position: fixed;
  height: 0%;
  width: 0%;
  pointer-events: none;

  background-color: white; /* Shutter Color */

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.shutter.on {
  opacity: 1; /* Shutter Transparency */
  height: 100%;
  width: 100%;
}

.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: calc(1 / 1 * 100%); /* The aspect ratio as percentage */
  position: relative;
}

.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-control {
  max-width: none;
  max-height: none;
  height: 100%;
}
