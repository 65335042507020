@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ============================================================ */
/* ========== [ 1. Global ] ========== */
/* ---------- [ Colors ] ---------- */
:root {
  --color-blue-hue: #0b5498;
  --color-blue-tint: #5487b7;
  --color-blue-shade: #1b458c;
  --color-yellow-hue: #fedc32;
  --color-yellow-tint: #fee770;
  --color-yellow-shade: #e5c62d;
  --color-red-hue: #ff0000;
  --color-red-tint: #ff4d4d;
  --color-grey-hue: #f4f3f3;
  --color-grey-tint: #fdfdfd;
  --color-grey-shade: #dddddd;
  --color-light-hue: #fbfbfb;
  --color-light-tint: #ffffff;
  --color-light-shade: #ededed;
  --color-dark-hue: #000000;
}

/* ============================================================ */
/* ========== [ 2. Structure ] ========== */
.no-margin {
  margin: 0px;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.right {
  float: right;
}
.full-width {
  width: 100%;
}
/* #root {
  padding: 0 1rem;
} */
/* ---------- [ Top bar ] ---------- */
#top-bar {
  height: 70px;
  background-color: #0b5498;
  background-color: var(--color-blue-hue);
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 15%;
  padding-right: 15%;
  display: grid;
  grid-template-columns: 1fr;
  align-content: end;
}
#top-bar + h1 {
  margin-top: 80px;
}
#top-bar + * {
  margin-top: 60px;
}

/* ------------------------------------------------------------ */
/* ---------- [ App ] ---------- */
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#offline {
  display: grid;
  grid-template-columns: 35px auto;
  background-color: #ff4d4d;
  background-color: var(--color-red-tint);
  color: #ffffff;
  color: var(--color-light-tint);
}
#offline svg {
  grid-row: 1/3;
}
#offline p:first-of-type {
  font-weight: 700;
}

#update {
  display: grid;
  grid-template-columns: 35px auto;
  background-color: #fedc32;
  background-color: var(--color-yellow-hue);
  align-items: center;
}
#update button {
  grid-column: 1/3;
}
#update .spinner {
  grid-column: 1/3;
  justify-self: center;
}

/* ---------- [ Auctions ] ---------- */
.auctionLink {
  border-bottom: 1px solid #dddddd;
  border-bottom: 1px solid var(--color-grey-shade);
  padding: 30px 0 30px;
  font-size: 1rem;
  font-weight: 300;
}
.auctionLink:first-child {
  padding-top: 0px;
}
.auctionLink:last-child {
  border-bottom: none;
}
form#newAuction {
  padding: 0 2rem;
}
@media screen and (max-width: 30em) {
  form#newAuction {
    padding: 0;
  }
}

.colleague-card hr {
  border-bottom: 1px solid #dddddd;
  border-bottom: 1px solid var(--color-grey-shade);
}

form#newAuction input {
  width: 100%;
}

/* ---------- [ Auction ] ---------- */
.auction-title {
  display: grid;
  grid-template-columns: 90% 10%;
  justify-content: left;
}
.accordion-button {
  border: 1px solid #5487b7;
  border: 1px solid var(--color-blue-tint);
  justify-content: normal;
}
.accordion-button-inner {
  justify-content: normal;
}
.accordion-button-inner:hover {
  box-shadow: none;
}
.accordion-button-inner > h5 {
  font-weight: 400;
  padding-left: 10px;
}
.accordion-button[aria-expanded='true'] {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.accordion-panel {
  background-color: #f4f3f3;
  background-color: var(--color-grey-hue);
}
.accordion-panel > p {
  display: grid;
  grid-template-columns: 17% auto 10%;
  justify-items: left;
  align-items: center;
  font-weight: 700;
}
@media screen and (max-width: 995px) {
  .accordion-panel > p {
    display: grid;
    grid-template-columns: auto 10%;
  }
  .accordion-panel > p > * {
    grid-row: 2/3;
  }
}
.accordion-panel > p > * {
  font-weight: 400;
}
.editable-item {
  display: grid;
  grid-template-columns: minmax(110px, 25%) 65% 10%;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .editable-item {
    grid-template-columns: minmax(110px, 25%) 60% 10%;
  }
}
.editable-item p:first-child {
  font-weight: 700;
}
.finished-value {
  background-color: #fbfbfb;
  background-color: var(--color-light-hue);
  padding: 5px 10px;
}
.editable-item button {
  justify-self: end;
}
.editable-item form {
  grid-column: 2/4;
}

/* ============================================================ */
/* ========== [ 3. Typography ] ========== */

:root {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #000000;
  color: var(--color-dark-hue);
}

h1 {
  margin-top: 0;
  font-size: 3.052em !important;
}

h2 {
  font-size: 2.441em !important;
}

h3 {
  font-size: 1.953em !important;
}

h4 {
  font-size: 1.563em !important;
}

h5 {
  font-size: 1.25em !important;
}

small,
.text_small {
  font-size: 0.833em !important;
}
/* ------------------------------------------------------------ */
/* ---------- [ Links ] ---------- */
a {
  color: #0b5498;
  color: var(--color-blue-hue);
  text-decoration: none;
}
a:hover {
  color: #5487b7 !important;
  color: var(--color-blue-tint) !important;
  text-decoration: underline;
}
a:visited {
  color: #1b458c;
  color: var(--color-blue-shade);
}
span.auction-title {
  align-self: center;
}
a.backLink {
  margin-bottom: 1rem;
}

/* ============================================================ */
/* ========== [ 4. Forms ] ========== */
/* ---------- [ Buttons ] ---------- */
button {
  border-radius: 10px !important;
  margin: 0.5rem 0rem;
  min-height: 40px !important;
  font-size: 1.3em !important;
  font-weight: 300 !important;
  justify-content: center;
  align-items: center;
}
button:hover {
  cursor: pointer;
  /* webkit-box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.75); */
  box-shadow: 2px 2px 10px -5px rgba(0, 0, 0, 0.75);
}
button.login {
  font-size: 1.3em;
  font-weight: 300;
  width: 50%;
}
button#logout img {
  height: 50px;
}
button.primary {
  color: #fbfbfb;
  color: var(--color-light-hue);
  border: none;
  background: #0b5498;
  background: var(--color-blue-hue);
  background: linear-gradient(to bottom, #0b5498 0%, #0b5498 100%);
  background: linear-gradient(to bottom, var(--color-blue-hue) 0%, var(--color-blue-hue) 100%);
  transition: all 0.3s;
}
button.primary:hover {
  color: #fee770;
  color: var(--color-yellow-tint);
  background: #5487b7;
  background: var(--color-blue-tint);
  background: linear-gradient(to bottom, #5487b7 0%, #0b5498 100%);
  background: linear-gradient(to bottom, var(--color-blue-tint) 0%, var(--color-blue-hue) 100%);
}
button.secondary {
  border: 1px solid #0b5498;
  border: 1px solid var(--color-blue-hue);
  color: #0b5498;
  color: var(--color-blue-hue);
  background: #fbfbfb;
  background: var(--color-light-hue);
  background: linear-gradient(to bottom, #fbfbfb 0%, #fbfbfb 100%);
  background: linear-gradient(to bottom, var(--color-light-hue) 0%, var(--color-light-hue) 100%);
}
button.secondary:hover {
  border: 1px solid #5487b7;
  border: 1px solid var(--color-blue-tint);
  color: #5487b7;
  color: var(--color-blue-tint);
  background: #fbfbfb;
  background: var(--color-light-hue);
  background: linear-gradient(to bottom, #fbfbfb 0%, #ededed 100%);
  background: linear-gradient(to bottom, var(--color-light-hue) 0%, var(--color-light-shade) 100%);
}
button.special {
  color: #0b5498;
  color: var(--color-blue-hue);
  border: 1px solid #5487b7;
  border: 1px solid var(--color-blue-tint);
  background: #fedc32;
  background: var(--color-yellow-hue);
  background: linear-gradient(to bottom, #fedc32 0%, #fedc32 100%);
  background: linear-gradient(to bottom, var(--color-yellow-hue) 0%, var(--color-yellow-hue) 100%);
}
button.special:hover {
  color: #5487b7;
  color: var(--color-blue-tint);
  background: #fee770;
  background: var(--color-yellow-tint);
  background: linear-gradient(to bottom, #fee770 0%, #fedc32 100%);
  background: linear-gradient(to bottom, var(--color-yellow-tint) 0%, var(--color-yellow-hue) 100%);
}
button.transparent {
  color: #0b5498;
  color: var(--color-blue-hue);
  background: transparent;
  border: none;
  height: 30px;
  max-width: 40px;
  padding: 0 !important;
}
button.transparent:hover {
  background: transparent;
}
button.transparent.light:hover {
  /* webkit-box-shadow: 2px 2px 10px -3px rgba(255, 255, 255, 0.85); */
  box-shadow: 0px 0px 10px -3px rgba(255, 255, 255, 0.85);
}
button.danger {
  border: 1px solid #0b5498;
  border: 1px solid var(--color-blue-hue);
  color: #0b5498;
  color: var(--color-blue-hue);
  background: #fbfbfb;
  background: var(--color-light-hue);
  background: linear-gradient(to bottom, #fbfbfb 0%, #fbfbfb 100%);
  background: linear-gradient(to bottom, var(--color-light-hue) 0%, var(--color-light-hue) 100%);
}
button.danger:hover {
  border: 1px solid #ff4d4d;
  border: 1px solid var(--color-red-tint);
  color: #ff0000;
  color: var(--color-red-hue);
  background: #fbfbfb;
  background: var(--color-light-hue);
  background: linear-gradient(to bottom, #fbfbfb 0%, #ededed 100%);
  background: linear-gradient(to bottom, var(--color-light-hue) 0%, var(--color-light-shade) 100%);
}
button.accept {
  border: 1px solid #0b5498;
  border: 1px solid var(--color-blue-hue);
  color: #0b5498;
  color: var(--color-blue-hue);
  background: #fbfbfb;
  background: var(--color-light-hue);
  background: linear-gradient(to bottom, #fbfbfb 0%, #fbfbfb 100%);
  background: linear-gradient(to bottom, var(--color-light-hue) 0%, var(--color-light-hue) 100%);
}
button.accept:hover {
  border: 1px solid #fee770;
  border: 1px solid var(--color-yellow-tint);
  color: #fedc32;
  color: var(--color-yellow-hue);
  background: #fbfbfb;
  background: var(--color-light-hue);
  background: linear-gradient(to bottom, #fbfbfb 0%, #ededed 100%);
  background: linear-gradient(to bottom, var(--color-light-hue) 0%, var(--color-light-shade) 100%);
}
button.icon svg {
  height: 28px;
}

/* ---------- [ Input ] ---------- */
input {
  border: 1px solid #dddddd !important;
  border: 1px solid var(--color-grey-shade) !important;
  border-radius: 10px !important;
  transition: all 0.3s;
  margin: 0.5rem 0;
  box-sizing: border-box;
}
input:focus {
  box-shadow: inset 2px 2px 11px -6px #c6c6c6 !important;
  border-color: #0b5498 !important;
  border-color: var(--color-blue-hue) !important;
}
input[type='text'],
input[type='tel'],
input[type='number'] {
  padding: 20px;
}

/* ============================================================ */
/* ========== [ 5. Other ] ========== */
/* ---------- [ HR ] ---------- */
hr {
  border-top: 1px solid #fee770;
  border-top: 1px solid var(--color-yellow-tint);
  margin: 2rem 0;
}

/*************************** Från Markus Renfors ***************************/

a.backLink {
  display: none !important;
}

button.primary {
  background: #0a5599;
}

button {
  font-weight: 600 !important;
}

.tovek-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 3em;
  z-index: 999;
  padding: 0 0.5em;
  grid-template-columns: 1fr;
}
.tovek-header button {
  /*display: none;*/
  font-size: 0.875em !important;
}

/* Plus button */
.add-call-plus-button {
  background: url('https://backend.tovek.se/images/app/app-add.png') no-repeat center center;
  background-color: transparent !important;
  background-size: 1.75rem;
  text-indent: 99rem;
  overflow: hidden;
  width: 2rem;
}

.auctionLink {
  display: grid;
  grid-template-columns: auto 7rem;
  padding: 1rem !important;
}

.auctionLink button {
  font-weight: bold !important;
  width: 0;
  overflow: hidden;
  grid-column: auto;
  background-size: 1em;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: 2em;
  display: block;
  width: 3rem;
  height: 3rem;
}

/* Finish button */
.finish-btn {
  border: 2px solid #4f9b29;
}
.finish-btn-completed {
  background-color: #4f9b29 !important;
  background-image: url('https://backend.tovek.se/images/app/app-check.png');
  color: white;
}
.finish-btn-uncompleted {
  background-color: white !important;
  background-image: url('https://backend.tovek.se/images/app/app-check-green.png');
  color: #4f9b29;
}

/* Share button */
.share-btn {
  grid-column: 2 !important;
  grid-row: 1;
  margin-right: 1.5em;
  border: 2px solid #0a5599;
  background-color: #0a5599 !important;
  background-image: url('https://backend.tovek.se/images/app/app-share.png') !important;
  color: white;
}

/* Share section button */
.is-shared-btn {
  margin-right: 1.5em;
  border: 2px solid #0a5599;
  background-color: #0a5599 !important;
  background-image: url('https://backend.tovek.se/images/app/app-check.png') !important;
}

/* New auction form */
#newAuction {
  padding: 1em !important ;
  display: grid;
  grid-template-columns: auto 8em;
  grid-gap: 1em;
  background: #eee;
}

/*Auction edit page*/
h2 {
  font-size: 1.75em !important;
  font-weight: 300 !important;
  padding: 0 1rem;
}

/* New call form */
.new-call-form .new-call-form-item {
  padding: 0.5em 1rem 0 1rem;
  margin: 0;
  position: relative;
}
.new-call-form .new-call-form-item label {
  position: absolute;
  z-index: 10;
  top: 0.5em;
  left: 2em;
  padding-bottom: 0;
  font-size: 0.75em;
  color: #0a5599;
}
.new-call-form-error-text {
  color: #d33;
  position: absolute;
  bottom: 0;
  right: 1.25em;
  font-size: 0.75em;
}
.new-call-form button {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  margin: 0.5em 0 0 0 !important;
  width: calc(100% - 2rem);
  z-index: auto !important;
}

/* Item listing */
.accordion-title {
  margin: 0;
}
.accordion-title > button {
  border-radius: 0 !important;
  border: 0;
  padding: 0.5rem 1rem;
  /* display: grid; */
  /* grid-template-columns: auto auto; */
  /* grid-gap: 0.5em; */
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
  z-index: 998;
}
.accordion-title > button .accordion-button-inner {
  overflow: hidden;
  white-space: nowrap;
}
.accordion-title > button .accordion-button-inner h5 {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
.accordion-title > button .upload-counter {
  /* position: relative;
  width: 4.5em;
  height: 1.5em;
  overflow: hidden; */
  padding-right: 1rem;
}
.accordion-title > button .upload-counter:before {
  content: '';
  padding: 0 0.75rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.accordion-title > button .upload-counter svg {
  position: absolute;
  right: 0;
}

/* All uploaded */
.accordion-title button.all-images-uploaded {
  background: #5db530;
  color: white;
}
/*.accordion-title button.all-images-uploaded .upload-counter:before {
  background-image: url('https://backend.tovek.se/images/app/app-uploaded.png');
}*/
.accordion-title button.all-images-uploaded .upload-counter svg {
  background: #5db530;
}

/* Some uploaded */
.accordion-title button.images-is-uploading {
  background: #f7e672;
}
/*.accordion-title button.images-is-uploading .upload-counter:before {
  background-image: url('https://backend.tovek.se/images/app/app-uploaded-black.png');
}*/
.accordion-title button.images-is-uploading .upload-counter svg {
  background: #f7e672;
}

/* No uploaded */
.accordion-title button.no-images-added {
  background: #f71b1b;
  color: white;
}
/*.accordion-title button.no-images-added .upload-counter:before {
  background-image: url('https://backend.tovek.se/images/app/app-uploaded.png');
}*/
.accordion-title button.no-images-added .upload-counter svg {
  background: #f71b1b;
}

/* Edit item */
.accordion-panel .css-j7qwjs .accordion-panel .css-j7qwjs {
  position: relative;
}
.take-photo-button {
  padding: 2rem;
  background: #0a5599 !important;
  color: white;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  /* border-radius: 0 !important; */
  margin-bottom: 1.5rem;
}
.upload-image-buton {
  padding: 2rem;
  background: #0a5599 !important;
  color: white;
  margin-bottom: 1rem;
}

.remove-call-button-div .remove-call-button {
  background: #d33 url('https://backend.tovek.se/images/app/app-remove.png') no-repeat 1rem center;
  color: white;
  background-size: 0.75em;
  overflow: hidden;
  text-align: left;
  border: 0;
  width: auto;
  margin: 1rem auto;
  text-indent: 1.5rem;
}
/* .accordion-panel button.css-w7cv0f {
  background-color: #d33 !important;
  background-size: 0.75em;
  width: 2em;
  overflow: hidden;
  text-align: left;
  border: 0;
  text-indent: 99em;
  padding: 0 !important;
  top: 0;
} */

.shutter {
  opacity: 0;
  transition: all 30ms ease-in; /* Shutter speed */
  position: fixed;
  height: 0%;
  width: 0%;
  pointer-events: none;

  background-color: white; /* Shutter Color */

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.shutter.on {
  opacity: 1; /* Shutter Transparency */
  height: 100%;
  width: 100%;
}

.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: calc(1 / 1 * 100%); /* The aspect ratio as percentage */
  position: relative;
}

.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-control {
  max-width: none;
  max-height: none;
  height: 100%;
}

